<template>
  <div class="answerDetails">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <div class="course-title">
            <div>
              <div class="name" v-html="detailList.title"></div>
              <div class="detailed">
                <span>题目提供者：{{detailList.user_real_name}}</span>
                <span>难度：{{detailList.diff_title}}</span>
                <span>提交：{{detailList.log_num}}</span>
                <span>通过：{{detailList.jige_total}}</span>
              </div>
            </div>
            <div class="edit">
              <div class="feedback" @click="handleCommand('feedback' , detailList.title , '答题记录')">
                <i class="el-icon-edit-outline"></i>反馈
              </div>
              <div class="button-primary" :style="{ marginLeft:'1rem',padding:'4px 20px'}" @click="goDetails">详情</div>
            </div>
          </div>
          <!-- 题目列表 -->
          <TopicSum v-loading="loading" :detailList="detailList" :deploy="{isAnswer:true}" @setPitchNums="setPitchNums" @setAnswers="setAnswers"></TopicSum>
        </div>
        <div class="content-right">
          <div class="gradeNum">
            <p class="num">78分</p>
            <p>提交于：2021-04-23 17:04</p>
          </div>
          <TopicList @topicClick="topicClick" :topicParam="topicParam" :pitchNums="pitchNums"></TopicList>
          <div class="recommend">
            <HotRecommend :title="'推荐题目'"></HotRecommend>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :bottom="100"> </el-backtop>
  </div>
</template>

<script>
// 题目列表和知识点标签
import TopicList from "@/components/TopicList/TopicList.vue"
import HotRecommend from "@/views/api/com/hotRecommend";
import TopicSum from "@/components/TopicSum/TopicSum.vue"
import { question_log_answer } from "@/api/topic.js"
export default {
  components: {
    TopicList, TopicSum,
    HotRecommend,
  },
  data () {
    return {
      radio: "",
      checkList: [],
      input: "",
      id: '',
      loading: false,
      topicParam: {
        topicList: [],
        knowledgeTag: [],
      },
      detailList: [],
      pitchNums: [],
      answers: [],
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getDetailList()
  },
  methods: {
    //滚动到点击的题目
    topicClick (selector) {
      document.getElementById(selector).scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    },
    goDetails () {
      this.$router.push({ path: '/problemList/overDetails', query: { id: this.id } })
    },
    getDetailList () {
      this.loading = true
      question_log_answer({ log_id: this.id }).then(res => {
        console.log(res)
        // this.detailList = res.data
        // this.pitchNums = res.data.questions.map(v => v.id)
        // this.topicParam = {
        //   topicList: [
        //     ...res.data.questions
        //   ],
        //   knowledgeTag: res.data.know_format,
        // }
        this.loading = false
      })
    },
    setPitchNums (pitchNums) {
      this.pitchNums = pitchNums
    },
    setAnswers (answers) {
      this.answers = answers
    }
  },
};
</script>

<style lang="less" scoped>
.answerDetails {
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 70%;
      .course-title {
        padding: 2rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .feedback {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
          }
          .el-icon-edit-outline {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
        .name {
          font-size: 21px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
        }
        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;
          > span {
            margin-right: 1.5rem;
          }
        }
      }
      .topic {
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        margin: 40px 0;
        padding: 0 2rem 2rem;
        ::v-deep {
          .el-radio__input.is-checked + .el-radio__label {
            color: #00957e;
          }
          .el-radio__input.is-checked .el-radio__inner {
            background: #00957e;
            border-color: #00957e;
          }
          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #00957e;
          }
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background: #00957e;
            border-color: #00957e;
          }
        }
        .filling {
          input {
            border: 0;
            color: #606266;
            font-size: 14px;
          }
          input:focus {
            outline: none;
          }
        }
        .topicType {
          margin: 3rem 0;
          font-size: 18px;
          color: #00957e;
        }
        .topicTitle {
          margin-top: 1rem;
        }

        .analysis {
          background: #ffffff;
          border: 1px solid #adadad;
          padding: 1rem;
          font-size: 14px;
          > div {
            line-height: 2rem;
          }
        }
        .group-item {
          margin: 1rem 0;
        }
      }
      .operation {
        margin-left: 0.8rem;
      }
    }
    .content-right {
      width: 27%;
      .gradeNum {
        margin-bottom: 40px;
        text-align: center;
        .num {
          font-size: 55px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #303030;
          line-height: 77px;
          margin: 1rem 0;
        }
      }
      .recommend {
        margin-top: 40px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        padding: 1rem;
        font-size: 14px;
      }
    }
  }
}
</style>
